<template>
  <div class="page" @touchmove="(e)=>{e.preventDefault()}">
    <div class="s1">
      <div class="wrap">
        <div class="flex fs-16 px-3">
          <div>店铺标题</div>
          <div class="flex-1"><input v-model="form.msgTitle" type="text" placeholder="请输入店铺标题" class="inp" maxlength="10"></div>
        </div>
        <div class="divider ml-3" />
        <div class="flex fs-16 p-3">
          <div class="flex-1">封面</div>
          <div>
            <div v-if="form.msgHref" class="avatar">
              <img :src="form.msgHref" mode="aspectFill" class="img">
              <!-- <div class="del" @click="form.msgHref=''" /> -->
            </div>
            <div v-else class="avatar add"><input class="file" type="file" @change="uploadImg"></div>
          </div>
        </div>
      </div>
    </div>

    <div class="p-footer h-end">
      <button class="btn auto" @click="goBack()">取消</button>
      <button class="btn auto fill ml-3" @click="submit">保存</button>
    </div>
  </div>
</template>

<script>
import { ossUploadFile } from '@/utils/index'
import img from '@/assets/miniapp-icon.png'
export default {
  data() {
    return {
      form: {
        msgType: 2013,
        msgTitle: '',
        msgContent: '',
        msgDes: '',
        msgHref: 'http://mallrobot-yh.oss-cn-hangzhou.aliyuncs.com/MR/20210416/lADPDg7mQXtrKLfNAljNAu4_750_600.jpg',
        logo: img
      }
    }
  },
  created() {

  },
  methods: {
    uploadImg(e) {
      if (e.target.files.length) {
        const file = e.target.files[0]
        ossUploadFile(file).then(res => {
          if (res) {
            this.form.msgHref = res
          }
        })
      }
    },
    validData() {
      const _this = this
      if (!_this.form.msgTitle) {
        this.$Toast('请输入店铺标题')
        return false
      }
      if (!_this.form.msgHref) {
        this.$Toast('请选择封面图片')
        return false
      }
      return true
    },
    goBack() {
      this.$router.go(-1)
    },
    submit() {
      if (!this.validData()) {
        return
      }
      this.$store.commit('setGlobalData', {
        miniapp: this.form
      })
      this.$router.go(-1)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "index";
</style>
